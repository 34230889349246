export const CategoryCatalog = [
  {
    title: 'AAEE de clientes',
    image: 'assets/images/case-categories/ico-category-tools.svg',
    queries: ['Información Solicitud de Amortización de AAEE', 'Seguimiento AAEE clientes'].sort(),
  },
  {
    title: 'Calidad de Producto',
    image: 'assets/images/case-categories/ico-category-quality.svg',
    queries: ['Consulta alérgenos', 'Incidencia en instalación de punto de venta', 'Otras dudas de calidad'].sort(),
  },
  {
    title: 'CLAVE',
    image: 'assets/images/case-categories/ico-category-clave2.png',
    queries: [
      'Cambiar el usuario y/o email del gerente',
      'Dudas Funcionamiento de la herramienta',
      'Error en diferencia de datos con mi ERP',
      'Error en los cuadros de mando',
      'Error funcionamiento de CLAVE',
      'Errores en la transmisión de ERP a CLAVE',
      'He olvidado mi contraseña CLAVE',
      'Modificar mi jerarquia de proveedores',
      'Otros temas de Clave',
    ],
  },
  {
    title: 'Facturación',
    image: 'assets/images/case-categories/ico-category-billing.svg',
    queries: [
      'Aplazamiento de deuda',
      'Cambio cuenta bancaria/correo electrónico',
      'Cambio forma de pago',
      'Consulta Esfuerzo comercial',
      'Descarga de facturas',
      'Detalles domiciliacion',
      'Facturas disponibles',
      'Informes Fiscales y Modelo 347',
      'Orion',
      'Otro tipo de información',
      'Pedidos no facturados',
      'Reclamación de abonos',
      'Reclamación factura albarán envases',
      'Reclamación diferencia de devoluciones',
      'Reclamacion factura no recibida',
      'Solicitud compensaciones',
    ],
  },
  {
    title: 'Gestion con tu equipo Distribución',
    image: 'assets/images/case-categories/ico-category-commercial.svg',
    queries: ['Acuerdos con terceros', 'Decoración de flotas', 'Decoración de naves', 'Otras dudas'],
  },
  {
    title: 'HEINET',
    image: 'assets/images/case-categories/ico-category-heinet.svg',
    queries: [
      'Acceso portal HEINET',
      'Error técnico HEINET',
      'Gestión de usuarios HEINET',
      'Otros temas de HEINET',
      'Solicitud de cambios de datos de HEINET',
    ],
  },
  {
    title: 'Herramientas',
    image: 'assets/images/case-categories/ico-category-tools.svg',
    queries: [
      'App de Mercado contraseña',
      'App de Mercado dudas',
      'App de mercado usuarios',
      'App Tu Bar cambio datos',
      'App Tu Bar contraseña',
      'App Tu Bar dudas',
      'App Tu Bar pedidos',
      'HEINET',
      'Otras consultas de Herramientas',
      'Plan vasos',
    ],
  },
  {
    title: 'Hesanet',
    image: 'assets/images/case-categories/ico-category-Hesanet.svg',
    queries: [
      'Consulta funcionamiento Hesanet',
      'Consultas/error transmisión hesanet',
      'Contraseña Hesanet',
      'Duda o Incidencia sobre el ECC',
      'Error transmisión ventas',
      'Log de errores',
    ],
  },
  {
    title: 'Nautilus',
    image: 'assets/images/case-categories/ico-category-Nautilus.svg',
    queries: [
      'Alta/baja Nautilus',
      'Consulta de Liquidaciones',
      'Error Acceso',
      'Error Funcionamiento',
      'Informes Nautilus',
    ],
  },
  {
    title: 'Pedidos',
    image: 'assets/images/case-categories/ico-category-orders.svg',
    queries: [
      'Consulta empresa de transporte',
      'Consulta hora de carga, horarios',
      'Devolución de producto',
      'Disponibilidad de producto',
      'Error acceso portal pedidos',
      'Error al solicitar producto',
      'Error en el catálogo',
      //'Error en la cesta',
      'Estado de mi pedido',
      'Localización de pedido',
      'Modificación pedido',
      'Otros temas de pedidos',
      'Pedido urgente',
      'Reclamaciones de Abono',
      'Retirada de envase',
      'Retrasar un pedido',
      //'Solicitud tapas de barril, pallet',
    ],
  },
  {
    title: 'PLV',
    image: 'assets/images/case-categories/ico-category-plv.svg',
    queries: [
      'Composición del pedido',
      'Compra/solicitud del pedido',
      'Destino incorrecto',
      'Fecha entrega del pedido',
      'Incidencia en PDV con botelleros',
      'Material mal etiquetado',
      'PLV recibido en mal estado',
    ],
  },
  {
    title: 'Suministros a terceros',
    image: 'assets/images/case-categories/ico-category-thirdParties.svg',
    queries: [
      'Consulta de código cliente HHCC',
      'Consulta de tarifas de almacenajes y suministros',
      'Error en la transmisión de albarán',
      'Modificación de albarán',
    ],
  },
  {
    title: 'EAZLE',
    image: 'assets/images/case-categories/ico-category-eazle.svg',
    queries: ['Dudas o incidencias con Eazle'],
  },
  {
    title: 'Otros',
    image: 'assets/images/case-categories/ico-category-tools.svg',
    queries: ['Modificar mis datos', 'Sugerencias HK'],
  },
];

export const typeOfCaseToNavigate: ItypeOfCaseToNavigate = {
  companyData: {
    category: CategoryCatalog.find((c: any) => c.title.toLowerCase() === 'otros'),
    query: 'Modificar mis datos',
  },
  footer: {
    category: CategoryCatalog.find((c: any) => c.title.toLowerCase() === 'otros'),
    query: 'Sugerencias HK',
  },
};
export const SearchResults = {
  title: 'Resultados',
  image: 'assets/images/empty-data-img/ico_empty_busqueda.svg',
  queries: [],
};
export interface ISearchResults {
  title: string;
  image: string;
  queries: string[];
}
interface ItypeOfCaseToNavigate {
  [key: string]: {
    category:
      | {
          title: string;
          image: string;
          queries: string[];
        }
      | undefined;
    query: string;
  };
}
