<div class="table__container">
  <table
    mat-table
    matSort
    [dataSource]="dataSource"
    class="mat-elevation-z8"
    (matSortChange)="emitSort($event)"
    [matSortDirection]="sortDirection"
    (matSortActive)="getSortDefault()"
    matSortDisableClear
    *ngIf="displayedColumns"
  >
    <!--- Note that these columns can be defined in any order.
            The actual rendered columns are set as a property on the row definition" -->

    <!-- Columns -->
    <ng-container [matColumnDef]="column.text" *ngFor="let column of displayedColumns">
      <!-- Header Table -->
      <tr class="table__header__row">
        <th
          mat-header-cell
          *matHeaderCellDef
          [class.mat__header__cell--align-center]="column.cellType === cellType.MODIFY"
          [class.row--thin]="column.cellType === cellType.ACTIONS || column.cellType === cellType.CHECKBOX"
          [style.width.%]="column.width ? column.width : null"
          class="mdc-data-table__header__row th--width"
        >
          <div *ngIf="column.cellType === cellType.CHECKBOX">
            <mat-checkbox
              (change)="$event ? toggleAllRows() : null"
              [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()"
              [aria-label]="checkboxLabel()"
              color="primary"
            >
            </mat-checkbox>
          </div>

          <div
            *ngIf="column.sortable.sortType === 'internal' && column.cellType !== cellType.CHECKBOX"
            [mat-sort-header]="column.key"
            [ngClass]="column.multipleIcons ? 'mat__header__text--multiple-icons' : ''"
          >
            <mat-icon
              [svgIcon]="'ico_info_outline'"
              *ngIf="column.infoIcon"
              [matTooltip]="column.infoIcon"
              [ngClass]="'custom-tooltip'"
            />
            <span
              class="ellipsis"
              #columnSpan
              [matTooltip]="column?.text ? column.text : ''"
              [matTooltipDisabled]="!isOverflow(columnSpan)"
              >{{ column.text }}</span
            >
          </div>

          <div
            *ngIf="column.sortable.sortType === 'external' && column.cellType !== cellType.CHECKBOX"
            [mat-sort-header]="''"
            [ngClass]="column.multipleIcons ? 'mat__header__text--multiple-icons' : ''"
          >
            <mat-icon
              [svgIcon]="'ico_info_outline'"
              *ngIf="column.infoIcon"
              [matTooltip]="column.infoIcon"
              [ngClass]="'custom-tooltip'"
            />
            <span
              class="ellipsis"
              #columnSpan
              [matTooltip]="column?.text ? column.text : ''"
              [matTooltipDisabled]="!isOverflow(columnSpan)"
              >{{ column.text }}</span
            >
          </div>

          <div
            *ngIf="!column.sortable.isSortable && column.cellType !== cellType.CHECKBOX"
            [ngClass]="column.multipleIcons ? 'mat__header__text--multiple-icons' : ''"
          >
            <div class="header__infoicon">
              <mat-icon
                [svgIcon]="'ico_info_outline'"
                *ngIf="column.infoIcon"
                [matTooltip]="column.infoIcon"
                [ngClass]="'custom-tooltip'"
              />
              <span
                class="ellipsis"
                #columnSpan
                [matTooltip]="column?.text ? column.text : ''"
                [matTooltipDisabled]="!isOverflow(columnSpan)"
                >{{ column.text }}</span
              >
            </div>
          </div>
        </th>
      </tr>
      <!-- Body Table -->

      <tr class="table__row" *ngIf="column">
        <td
          mat-cell
          #tdElement
          *matCellDef="let row; index as i"
          [abbr]="column?.text"
          [attr.contenteditable]="row.isEditable"
          (blur)="onEdit($event, column.key, i)"
          [class.mat__cell--align-center]="column.cellType === cellType.MODIFY"
          [ngClass]="row.disabled ? 'row--disabled' : ''"
          class="mdc-data-table__row"
          [matTooltip]="row[column.key]"
          [matTooltipDisabled]="!isOverflow(tdElement)"
        >
          <span *ngIf="column.cellType === cellType.CHECKBOX" class="row__span__content row--thin">
            <mat-checkbox
              (click)="$event.stopPropagation()"
              (change)="[$event ? selection.toggle(row) : null, setSelectedRow(row, selection.isSelected(row))]"
              [checked]="selection.isSelected(row)"
              [aria-label]="checkboxLabel(row)"
              color="primary"
            >
            </mat-checkbox>
          </span>
          <span *ngIf="column.cellType === cellType.TEXT" class="row__span__content">
            {{ row[column.key] }}
            <mat-icon
              (click)="handleCopyToClipboard(row[column.key])"
              *ngIf="column.isClipboarded && row[column.key]"
              class="mat__icon--small"
              [svgIcon]="'ico_copy'"
            />
          </span>
          <span *ngIf="column.cellType === cellType.EYE" class="table__row__icons">
            <mat-icon
              [svgIcon]="'ico_eye'"
              (click)="viewDetails(row, 'view')"
              *ngIf="row.documentType !== invoiceType"
            />
            <mat-icon
              [svgIcon]="'ico_download'"
              (click)="viewDetails(row, 'download')"
              *ngIf="row.documentType !== invoiceType"
            />
          </span>
          <span *ngIf="column.cellType === cellType.DOWNLOADANDVIEWIFPDF" class="table__row__icons">
            <mat-icon
              [svgIcon]="'ico_eye'"
              (click)="viewDetails(row, 'view')"
              *ngIf="
                row.documentType !== invoiceType && row.documentationName.split('.').pop()!.toLowerCase() === 'pdf'
              "
            />
            <mat-icon
              [svgIcon]="'ico_download'"
              (click)="viewDetails(row, 'download')"
              *ngIf="row.documentType !== invoiceType"
            />
          </span>

          <span *ngIf="column.cellType === cellType.EDIT && !row.disabled" class="icon-container">
            <mat-icon
              (click)="viewDetails(row, 'edit')"
              *ngIf="!row.isEditable"
              [svgIcon]="'pencil-icon'"
              [ngClass]="{ disabled: row.role.toLowerCase() === Roles.GERENTE }"
            />
            <mat-icon
              *ngIf="row.role"
              (click)="disabledDetails(row)"
              [svgIcon]="'delete-user'"
              class="mat__row--second__icon"
              [ngClass]="{ disabled: row.role.toLowerCase() === Roles.GERENTE }"
            />
          </span>

          <span *ngIf="column.cellType === cellType.ACTIONS" class="icon-container row--thin">
            <mat-icon
              (click)="redirect(column.link, row[column.key])"
              *ngIf="!row.isEditable"
              [svgIcon]="'ico_rightArrow'"
            />
          </span>

          <span
            *ngIf="column.cellType === cellType.EDIT && row.disabled"
            class="icon-container table__row__text--icon"
            (click)="viewDetails(row, 'enableUser')"
          >
            <mat-icon class="false__button-icon" *ngIf="!row.isEditable" [svgIcon]="'add-person-icon'" />
            <!-- FIXME: hardcoded text -->
            <span class="mat__link row__span__content"><a>Activar</a></span>
          </span>

          <span
            *ngIf="column.cellType === cellType.LINK"
            class="mat__link row__span__content"
            [ngClass]="row.disabled ? 'row--disabled' : ''"
          >
            <a
              (click)="redirect(column?.link, row[column.key])"
              #aElement
              [matTooltip]="row[column.key]"
              [matTooltipDisabled]="!isOverflow(aElement)"
              >{{ row[column.key] }}</a
            >
            <mat-icon
              (click)="handleCopyToClipboard(row[column.key])"
              class="mat__icon--small"
              *ngIf="column.isClipboarded"
              [svgIcon]="'ico_copy'"
            />
          </span>
          <span
            *ngIf="column.cellType === cellType.LINKOPENINVOICES"
            [ngClass]="row.disabled ? 'row--disabled' : ''"
            class="mat__link row__span__content"
          >
            <ng-container
              *ngIf="row['entryTypeId'] === 'Factura' || row['entryTypeId'] === 'Autofactura'; else notFactura"
            >
              <a
                (click)="redirect(column?.link, row[column.key])"
                #aElement
                [matTooltip]="row[column.key]"
                [matTooltipDisabled]="!isOverflow(aElement)"
                >{{ row[column.key] }}</a
              >
            </ng-container>
            <ng-template #notFactura class="row__span__content">
              {{ row[column.key] }}
            </ng-template>
            <mat-icon
              (click)="handleCopyToClipboard(row[column.key])"
              class="mat__icon--small"
              *ngIf="row[column.key] !== '' && column.isClipboarded"
              [svgIcon]="'ico_copy'"
            />
          </span>
          <span
            *ngIf="column.cellType === cellType.VISUALPDF"
            class="mat__link row__span__content"
            [ngClass]="row.disabled ? 'row--disabled' : ''"
          >
            <a
              (click)="row.documentType !== invoiceType ? viewDetails(row, 'view') : null"
              #aElement
              [matTooltip]="row[column.key]"
              [matTooltipDisabled]="!isOverflow(aElement)"
              >{{ row[column.key] }}</a
            >
            <mat-icon
              (click)="handleCopyToClipboard(row[column.key])"
              class="mat__icon--small"
              *ngIf="column.isClipboarded"
              [svgIcon]="'ico_copy'"
            />
          </span>
          <app-tag *ngIf="column.cellType === cellType.MODIFY" [tagText]="row[column.key]" />
        </td>
        <div class="separador"></div>
      </tr>
    </ng-container>
    <!-- End Columns-->

    <tr mat-header-row *matHeaderRowDef="displayedColumnsTexts"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumnsTexts"></tr>
  </table>
</div>
<mat-paginator
  *ngIf="tableConfig.showPaginator"
  #paginator
  class="paginator"
  (page)="handlePageEvent($event)"
  [length]="tableConfig.totalElements"
  [pageSize]="tableConfig.pageSize"
  [pageIndex]="tableConfig.initialPage"
  aria-label="Select page"
  [showFirstLastButtons]="tableConfig.showFirstLastButtons"
  [pageSizeOptions]="tableConfig.pageSizeOptions"
  [hidePageSize]="tableConfig.hidePageSizaOptions"
>
</mat-paginator>
